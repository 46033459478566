import { abi } from "./abi";
import {
    MINTED_CHECK_CAP,
} from "./const";
import {
    MAX_AMOUNT,MINT_AMOUNT,INSCRIPTION_ADDRESS_AMOUNT
} from "./configuration";

var interId;

const div = function (arg1,arg2,digit){
    var t1=0,t2=0,r1,r2;
    try{t1=arg1.toString().split(".")[1].length}catch(e){}
    try{t2=arg2.toString().split(".")[1].length}catch(e){}
    r1=Number(arg1.toString().replace(".",""))
    r2=Number(arg2.toString().replace(".",""))
    //获取小数点后的计算值
   var result= ((r1/r2)*Math.pow(10,t2-t1)).toString()
    var result2=result.split(".")[1];
    if(result2==null){
        result2 = "";
    }
    result2=result2.substring(0,digit>result2.length?result2.length:digit);
  
    return Number(result.split(".")[0]+"."+result2);
  }
  
  const mul = function accMul(arg1,arg2)
  {
    var m=0,s1=arg1.toString(),s2=arg2.toString();
    try{m+=s1.split(".")[1].length}catch(e){}
    try{m+=s2.split(".")[1].length}catch(e){}
    return Number(s1.replace(".",""))*Number(s2.replace(".",""))/Math.pow(10,m)
  }

export const getUserState = async (data) => {
    try {
        getMintAmount(data)
        getBalance(data)
    } catch (err) {
        console.error(err.message);
    }
}

export const getMintedRecur = async (data) => {
    try {
        getUserState(data)
        if (interId) {
            clearInterval(interId);
        } else {
            interId = setInterval(async function () {
                getUserState(data)
            }, MINTED_CHECK_CAP);
        }

    } catch (err) {
        console.error(err.message);
    }
};

function getBit (value, bit = 18) {
    let str = value.toString();
    let strIndex = str.indexOf('.');
    if (strIndex === -1) return str;
    str = str.substring(0, strIndex + bit);
    return str;
  }




export const getMintAmount = async (data) => {
    try {
        fetch(MINT_AMOUNT, {
            mode: 'cors'
          })
        .then(response => {
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            return response.json();
        })
        .then(resp => {
            data.setState({
                mintAmount: resp.data+"0",
                percentage:(div(resp.data,MAX_AMOUNT)*100).toFixed(2)+"%",
            })
        })
        .catch(error => console.error('There was a problem with the fetch operation:', error));

    } catch (err) {
        console.error(err.message);
    }
};

export const getBalance = async (data) => {
    if(data.state.address!="")
    {
    try {
        fetch(INSCRIPTION_ADDRESS_AMOUNT+data.state.address, {
            mode: 'cors'
          })
        .then(response => {
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            return response.json();
        })
        .then(resp => {
            data.setState({
                balance: resp.data+"0",
            })
        })
        .catch(error => console.error('There was a problem with the fetch operation:', error));

    } catch (err) {
        console.error(err.message);
    }
}
};