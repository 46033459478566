// Set smart contract based on chain id
export const CONTRACTADDRESS = {
    10: ["","","","","","",""],
    56: ["","","","","","",""],
};

export const BETADDRESS = {
    5: ["","","","","","",""],
    97: ["","","","","","",""],
};

export const BRIDGEADDRESS = {
    97: ["","","","","","",""],
    5: ["","","","","","",""],
};

export const LUCKYADDRESS = {
    97: ["","","","","","",""],
    5: ["","","","","","",""],
    7899: ["","","","","","",""],
};


// Set the price of your NFT
export const PRICE = 0.001;

// Set the price of deposit
export const DEPOSIT_PRICE = 0;

// Set the price of your NFT
export const BET_PRICE = 1;
// Set the price of your NFT
export const AIRDROP_PRICE = 0;

// Set the price of your NFT
export const REWARD_PRICE = 0;

// Set the max amount of your NFT collection
export const MAX_AMOUNT = 21000000;

// Set the max batch size per wallet 
export const MAX_BATCH_SIZE = 5;

// Set the white list price of your NFT
export const WHITE_LIST_PRICE = 0;

// Set the white list max batch size per wallet 
export const WHITE_LIST_NUM = 1;

export const CURRENCY = "AI";

export const NFT_NAME = "COMING SOON" ;

export const CHAIN_NAME = "AI CHAIN";

export const MINT_AMOUNT= "https://api.aiscription.xyz/api/v1/aiinscription/totalAmount"

export const INSCRIPTION_ADDRESS_AMOUNT= "https://api.aiscription.xyz/api/v1/aiinscription/addressAmount/"


// Url of network 
 export const RPC_URL = "https://bsc-dataseed.binance.org"; 
//export const RPC_URL = "https://data-seed-prebsc-2-s1.bnbchain.org:8545"; /*bsc test*/

//export const RPC_URL = "https://mainnet.ethereumpow.org";
//export const RPC_URL = "https://kovan.optimism.io/";
//export const RPC_URL = "https://sv.svchain.link";
//export const RPC_URL = "https://endpoints.omniatech.io/v1/op/mainnet/public";

//Url of id
export const CHAIN_ID = 56;

//export const CHAIN_ID = 7899;
//export const CHAIN_ID = 69;
//export const CHAIN_ID = 97;
export const DEFAULT_CHAIN_ID = 56;

// Set the opesnea url 
export const OPENSEA_URL = "https://tofunft.com/"

export const PLATFORM = "tofunft"

export const BASE_TOKEN_URI = "ipfs://QmQ9ta56se8bb7KpP5Mwc7VCA54JCxn8CwyU9XcDL4VVGr/"

export const BASE_MINER_URI = "ipfs://QmTyacz9nVrkdvfZiNFeEZpHRkgAPed2uM5MHiRipHfPki/"

export const BASE_ROCKET_URI = "ipfs://Qmb97yftkvcSFJTXoYAdTjFnhgSniEjt5Nb3t8pp9t3pcz/"