export const formatWalletAddress = (address) => {
  return address && `${address.substring(0, 4)}...${address.substring(40)}`;
};

export const formatTxn = (txn) => {
  return txn && `${txn.substring(0, 4)}...${txn.substring(62)}`;
};



export const transactionHostURL = (chainId) => {
  let host = "https://etherscan.io/tx";
  switch (Number(chainId)) {
    case 4:
        host = "https://rinkeby.etherscan.io/tx";
    break;
    case 5:
        host = "https://goerli.etherscan.io/tx";
    break;
    case 97: 
        host =  "https://testnet.bscscan.com//tx";
    break;
    case 69:
        host = "https://kovan-optimistic.etherscan.io/tx";
    break;  
    case 10:
        host = "https://optimistic.etherscan.io/tx";
    break;  
    case 10001:
        host = "https://www.oklink.com/en/ethw/tx";
    break;  
    case 1116:
        host = "https://scan.coredao.org/tx";
    break;
    case 56:
      host = "https://bscscan.com/tx";
    break;  
    case 7899:
    host = "https://www.svchain.link/tx";
     break;
      case 109:
      host = "https://www.shibariumscan.io/tx";
      break;
      case 198:
          host = "https://explorer.bitchain.biz/tx";
    default:
    break;

  }
  return host;
};

export const AddressHostURL = (chainId) => {
    let host = "https://etherscan.io/token";
    switch (Number(chainId)) {
        case 4:
            host = "https://rinkeby.etherscan.io/token";
            break;
        case 5:
            host = "https://goerli.etherscan.io/token";
            break;
        case 97:
            host =  "https://testnet.bscscan.com/token";
            break;
        case 69:
            host = "https://kovan-optimistic.etherscan.io/token";
            break;
        case 10:
            host = "https://optimistic.etherscan.io/token";
            break;
        case 10001:
            host = "https://www.oklink.com/en/ethw/token";
            break;
        case 1116:
            host = "https://scan.coredao.org/token";
            break;
        case 56:
            host = "https://bscscan.com/token";
            break;
        case 7899:
            host = "https://www.svchain.link/token";
            break;
        case 109:
            host = "https://www.shibariumscan.io/address";
            break;
        case 198:
            host = "https://explorer.bitchain.biz/token";
            break;
        default:
            break;

    }
    return host;
};
