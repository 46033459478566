import React from 'react';
import "./index.css";
import Web3Modal from "web3modal";
import { useState } from "react";
import WalletLink from "walletlink";
import WalletConnectProvider from "@walletconnect/web3-provider";
import { MAX_BATCH_SIZE} from "../../utils/configuration";
import { DETAIL } from "../aicoin/detail.js";
import intl from 'react-intl-universal';
import img from "../../assets/ai.gif";



var isGetBalance = false

const ctx = require.context("../../assets/slider-imgs", true, /.png$/);
let images = [];
ctx.keys().forEach((key) => {
  images.push(ctx(key));
});


const providerOptions = {
  walletlink: {
    package: WalletLink, // Required
    options: {
      appName: "AMPLW GAME", // Required
      infuraId: "INFURA_ID", // Required unless you provide a JSON RPC url; see `rpc` below
      rpc: "", // Optional if `infuraId` is provided; otherwise it's required
      chainId: 1, // Optional. It defaults to 1 if not provided
      appLogoUrl: null, // Optional. Application logo image URL. favicon is used if unspecified
      darkMode: false, // Optional. Use dark theme, defaults to false
    },
  },
  walletconnect: {
    display: {
      name: "Mobile",
    },
    package: WalletConnectProvider,
    options: {
      infuraId: "INFURA_ID", // required
    },
  },
};



export const HOME = ({ data }) => {

    const locales = {
        "en-US": require('../../locales/en-US.json'),
        "zh-CN": require('../../locales/zh-CN.json'),
    };

  data.web3Modal = new Web3Modal({
    network: "mainnet",
    cacheProvider: true,
    providerOptions: providerOptions,
  });

  const [count, setCount] = useState(1);

const NumberDiv = function (arg1,arg2,digit){
  var t1=0,t2=0,r1,r2;
  try{t1=arg1.toString().split(".")[1].length}catch(e){}
  try{t2=arg2.toString().split(".")[1].length}catch(e){}
  r1=Number(arg1.toString().replace(".",""))
  r2=Number(arg2.toString().replace(".",""))
  //获取小数点后的计算值
 var result= ((r1/r2)*Math.pow(10,t2-t1)).toString()
  var result2=result.split(".")[1];
  if(result2==null){
      result2 = "";
  }
  result2=result2.substring(0,digit>result2.length?result2.length:digit);

  return Number(result.split(".")[0]+"."+result2);
}

const multiply = function accMul(arg1,arg2)
{
  var m=0,s1=arg1.toString(),s2=arg2.toString();
  try{m+=s1.split(".")[1].length}catch(e){}
  try{m+=s2.split(".")[1].length}catch(e){}
  return Number(s1.replace(".",""))*Number(s2.replace(".",""))/Math.pow(10,m)
}

  function setMax(){
    data.setState({
      mintAmout: data.state.balance==null?1:MAX_BATCH_SIZE-data.state.balance
  });
   }

   function add(){
    var amount = ++data.state.mintAmout;
    data.setState({
      mintAmout: data.state.balance==null?1:amount>=MAX_BATCH_SIZE-data.state.balance?MAX_BATCH_SIZE-data.state.balance:amount,
  });
}

   function remove(){
    var amount = --data.state.mintAmout;
    data.setState({
      mintAmout: data.state.balance==null?1:amount<=0?1:amount,
  });
   }


   const getSellOupt = (e) => {
    var len1 = e.target.value.substr(0, 1);
    var text = /^[0-9]+$/

    if(e.target.value==="")
    {
    data.setState({
      mintAmout:e.target.value,
     });
     }else if (len1 === "0") {
      data.setState({
        mintAmout:"1",
       });
     } else if (e.target.value>MAX_BATCH_SIZE-data.state.balance) {
      data.setState({
        mintAmout:MAX_BATCH_SIZE-data.state.balance,
       });
     }else if(text.test(e.target.value))
    {
    data.setState({
      mintAmout:e.target.value,
  });
    }
   }

  const [top, setTop] = useState(0);



  return (
    <div>
      <nav className={"navbar  navbar-inverse"}></nav>
      <div className={"main-section"}>

        <div className={"main-section-title"}>{intl.get('PROJECT')}</div>
        {/*<div className={"main-section-subtitle-title"}>{intl.get('PRIZE_POOL')}：{data.state.balanceOfStake} {CURRENCY} </div>*/}

        <div className={"main-section-title-mobile"}>{intl.get('PROJECT')}</div>
        {/*<div className={"main-section-subtitle-mobile"}>{intl.get('PRIZE_POOL')}：{data.state.balanceOfStake} {CURRENCY} </div>*/}
        <img src={img} className="accessory-img" />

        <div className="newlanding-mint-container">
         <DETAIL  data={data}  />
         </div>

      </div>
    </div>
    
  );
};


