import Web3 from "web3";
import {getLuckyRecur, getLuckyStatus} from "./luckyStatus";
import { CHAIN_ID,DEFAULT_CHAIN_ID } from "./configuration";
import {chainList} from "./chain.js";

/**
 * Kick in the UI action after Web3modal dialog has chosen a provider
 */
let chainId;
let chainName;
let rpcUrls;
let name;
let symbol;
let decimals;
let blockExplorerUrls;
export const onConnect = async (data) => {

  const provider = await data.web3Modal.connect();

  await subscribeProvider(provider, data);

  // await provider.enable();
  if (window.ethereum) {
    await window.ethereum.request({ method: 'eth_requestAccounts' });
  }

  const web3 = new Web3(provider);

  const accounts = await web3.eth.getAccounts();

  const address = accounts[0];

  const networkId = await web3.eth.net.getId();

  const chainId = await web3.eth.getChainId();

  const balance = await web3.eth.getBalance(accounts[0]);

//  if(networkId!==DEFAULT_CHAIN_ID)
//  {
//   await switchDefaultChain(data)
//  }
  await data.setState({
    web3,
    provider,
    connected: true,
    address,
    chainId,
    networkId,
    mintTransaction: "",
    pendingRequest: false,
    txn: "",
    finish: false,
    mintableNum: -1,
    tier: 0,
    modalDialogTitle: "",
    modalDialogBodyText: "",
    containedModalShow: "",
    mintErrorMsg: "",
    walletWinBet:0,
    walletDrawBet:0,
    walletLoseBet:0,
    rewardAmount:0,
    betAmount:"",
    balance: getBit(web3.utils.fromWei(balance)),
  },() => {getLuckyRecur(data)});

};




// const setChainInfo= async (data)=>{
//   chainList.forEach((item) => {
//     if(item.public===true){
//
//       if(Number(item.chainId)===Number(data.state.chainIdTo))
//       {
//         chainId = item.chainId
//         chainName = item.chainName
//         rpcUrls = item.rpcUrls
//         name = item.nativeCurrency.name
//         symbol = item.nativeCurrency.symbol
//         decimals = item.nativeCurrency.decimals
//         blockExplorerUrls = item.blockExplorerUrls
//       }
//     }
//   });
// }

const setDefaultChainInfo= async (data)=>{
  chainList.forEach((item) => {
    if(item.public===true){

      if(Number(item.chainId)===Number(DEFAULT_CHAIN_ID))
      {
        chainId = item.chainId
        chainName = item.chainName
        rpcUrls = item.rpcUrls
        name = item.nativeCurrency.name
        symbol = item.nativeCurrency.symbol
        decimals = item.nativeCurrency.decimals
        blockExplorerUrls = item.blockExplorerUrls
      }
    }
  });
}



export const setChainInfo= async (item,data,e)=>{
        chainId = item.chainId
        chainName = item.chainName
        rpcUrls = item.rpcUrls
        name = item.nativeCurrency.name
        symbol = item.nativeCurrency.symbol
        decimals = item.nativeCurrency.decimals
        blockExplorerUrls = item.blockExplorerUrls
  await setChain(data)
}

const switchToETHW = async (data) => {
  const web3 = data.state.web3;
  try {
    await web3.currentProvider.request({
      method: "wallet_switchEthereumChain",
      params: [{ chainId: web3.utils.numberToHex(data.state.chainId) }],
    });
  } catch (error) {
    if (error.code === 4902) {
      try {
              await web3.currentProvider.request({
                method: "wallet_addEthereumChain",
                params: [
                  {
                    chainId: web3.utils.numberToHex(CHAIN_ID),
                    chainName: "ETHW",
                    rpcUrls: ["https://mainnet.ethereumpow.org"],
                    nativeCurrency: {
                      name: "ETHW",
                      symbol: "ETHW",
                      decimals: 18,
                    },
                    blockExplorerUrls: ["https://mainnet.ethwscan.com"],
                  },
                ],
              });

      } catch (error) {
        alert(error.message);
      }
    }
  }
}

const switchToBSC = async (web3) => {
  try {
    await web3.currentProvider.request({
      method: "wallet_switchEthereumChain",
      params: [{ chainId: web3.utils.numberToHex(56) }],
    });
  } catch (error) {
    if (error.code === 4902) {
      try {
        await web3.currentProvider.request({
          method: "wallet_addEthereumChain",
          params: [
            {
              chainId: web3.utils.numberToHex(56),
              chainName: "BSC",
              rpcUrls: ["https://bsc-dataseed.binance.org"],
              nativeCurrency: {
                name: "BNB",
                symbol: "BNB",
                decimals: 18,
              },
              blockExplorerUrls: ["https://bscscan.com/"],
            },
          ],
        });
      } catch (error) {
        alert(error.message);
      }
    }
  }
}

export const switchChain = async (data) => {
  const web3 = data.state.web3;
  if(data.state.connected!=true){
    try {
      await onConnect(data)
    } catch (e) {
      console.error('Wallet connection failed. Reason:', e.message);
    }
  }
  setDefaultChainInfo(data)
  try {
    await web3.currentProvider.request({
      method: "wallet_switchEthereumChain",
      params: [{ chainId: web3.utils.numberToHex(chainId) }],
    },()=>{
      try {
         onConnect(data)
      } catch (e) {
        console.error('Wallet connection failed. Reason:', e.message);
      }
    });
  } catch (error) {
    if (error.code === 4902) {
      try {
        await web3.currentProvider.request({
          method: "wallet_addEthereumChain",
          params: [
            {
              chainId: web3.utils.numberToHex(chainId),
              chainName: chainName,
              rpcUrls: rpcUrls,
              nativeCurrency: {
                name: name,
                symbol: symbol,
                decimals: decimals,
              },
              blockExplorerUrls: blockExplorerUrls,
            },
          ],
        },()=>{
          try {
            onConnect(data)
          } catch (e) {
            console.error('Wallet connection failed. Reason:', e.message);
          }
        });
      } catch (error) {
        alert(error.message);
      }
    }
  }
}



export const setChain = async (data) => {
  const web3 = data.state.web3;
  try {
    await web3.currentProvider.request({
      method: "wallet_switchEthereumChain",
      params: [{ chainId: web3.utils.numberToHex(chainId) }],
    },()=>{
      try {
        onConnect(data)
      } catch (e) {
        console.error('Wallet connection failed. Reason:', e.message);
      }
    });
  } catch (error) {
    if (error.code === 4902) {
      try {
        await web3.currentProvider.request({
          method: "wallet_addEthereumChain",
          params: [
            {
              chainId: web3.utils.numberToHex(chainId),
              chainName: chainName,
              rpcUrls: rpcUrls,
              nativeCurrency: {
                name: name,
                symbol: symbol,
                decimals: decimals,
              },
              blockExplorerUrls: blockExplorerUrls,
            },
          ],
        },()=>{
          try {
            onConnect(data)
          } catch (e) {
            console.error('Wallet connection failed. Reason:', e.message);
          }
        });
      } catch (error) {
        alert(error.message);
      }
    }
  }
}

export const switchDefaultChain = async (data) => {
  const web3 = data.state.web3;
  try {
    await web3.currentProvider.request({
      method: "wallet_switchEthereumChain",
      params: [{ chainId: web3.utils.numberToHex(DEFAULT_CHAIN_ID) }],
    });
  } catch (error) {
    if (error.code === 4902) {
      try {
        setDefaultChainInfo(data)
        await web3.currentProvider.request({
          method: "wallet_addEthereumChain",
          params: [
            {
              chainId: web3.utils.numberToHex(chainId),
              chainName: chainName,
              rpcUrls: rpcUrls,
              nativeCurrency: {
                name: name,
                symbol: symbol,
                decimals:decimals,
              },
              blockExplorerUrls: blockExplorerUrls,
            },
          ],
        });
      } catch (error) {
        alert(error.message);
      }
    }
  }
}

export const onChange = async (data) => {


  const provider = await data.web3Modal.connect();

  await subscribeProvider(provider, data);

  //await provider.enable();
  if (window.ethereum) {
    await window.ethereum.request({ method: 'eth_requestAccounts' });
  }

  const web3 = new Web3(provider);

  const accounts = await web3.eth.getAccounts();

  const address = accounts[0];

  const networkId = await web3.eth.net.getId();

  const chainId = await web3.eth.getChainId();

  const balance = await web3.eth.getBalance(accounts[0]);


  await data.setState({
    web3,
    provider,
    connected: true,
    address,
    chainId,
    networkId,
    mintTransaction: "",
    pendingRequest: false,
    txn: "",
    finish: false,
    mintableNum: -1,
    tier: 0,
    modalDialogTitle: "",
    modalDialogBodyText: "",
    containedModalShow: "",
    mintErrorMsg: "",
    walletWinBet:0,
    walletDrawBet:0,
    walletLoseBet:0,
    rewardAmount:0,
    betAmount:"",
    balance: getBit(web3.utils.fromWei(balance)),
    hoverIndex:null
  },() => {getLuckyStatus(data)});
};


function getBit (value, bit = 3) {
  let str = value.toString();
  let strIndex = str.indexOf('.');
  if (strIndex === -1) return str;
  str = str.substring(0, strIndex + bit);
  return str;
}

const subscribeProvider = async (provider, data) => {
  if (!provider.on) {
    return;
  }

  provider.on("disconnect", async (accounts) => {
    console.info("disconnect")

  });
  provider.on("accountsChanged", async (accounts) => {
      const {web3} = data.state;
    if(accounts!=null && accounts.length>0) {
      const balance = await web3.eth.getBalance(accounts[0]);
      await data.setState({
        address: accounts[0],
        isConnected: true,
        balance: getBit(web3.utils.fromWei(balance)),
        tier: 0
      }, () => {
        getLuckyStatus(data)
      });
    }else{
      await data.setState({
        isConnected: false,
        address:""
      });
    }
  });

  // provider.on("chainChanged", async (chainId) => {
  //   await data.setState({
  //     isGetBalance: false,
  //   });
  //
  //   const { web3 } = data.state;
  //   const networkId = await web3.eth.net.getId();
  //   const balance = await web3.eth.getBalance(data.state.address);
  //   await data.setState({
  //     chainId, networkId,
  //     balance: getBit(web3.utils.fromWei(balance)),
  //     mintType: ""
  //   },()=>{getLuckyStatus(data)});
  //
  // });

  provider.on("chainChanged", async (networkId) => {
    await data.setState({
      isGetBalance: false,
    });

     const { web3 } = data.state;
    const chainId = await web3.eth.getChainId();
    const balance = await web3.eth.getBalance(data.state.address);
    await data.setState({
      chainId, networkId,
      balance: getBit(web3.utils.fromWei(balance)),
      mintType: "",
    },()=>{getLuckyStatus(data)});
  });
};

