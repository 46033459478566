export const abi = [
        {
            "inputs": [
                {
                    "internalType": "address",
                    "name": "to",
                    "type": "address"
                },
                {
                    "internalType": "uint256",
                    "name": "tokenId",
                    "type": "uint256"
                }
            ],
            "name": "approve",
            "outputs": [],
            "stateMutability": "nonpayable",
            "type": "function"
        },{
        "inputs": [
            {
                "internalType": "uint256",
                "name": "amount",
                "type": "uint256"
            },
            {
                "internalType": "uint256",
                "name": "lockUntil",
                "type": "uint256"
            }
        ],
        "name": "deposit",
        "outputs": [],
        "stateMutability": "payable",
        "type": "function"
    },{
        "inputs": [
            {
                "internalType": "address",
                "name": "owner",
                "type": "address"
            }
        ],
        "name": "balanceOf",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },{
        "inputs": [
            {
                "internalType": "address",
                "name": "owner",
                "type": "address"
            },{
                "internalType": "address",
                "name": "spender",
                "type": "address"
            }
        ],
        "name": "allowance",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },{
        "inputs": [
            {
                "internalType": "uint256",
                "name": "deltaShare",
                "type": "uint256"
            }
        ],
        "name": "withdraw",
        "outputs": [],
        "stateMutability": "view",
        "type": "function"
    },{
        "inputs": [],
        "name": "getMyReward",
        "outputs": [],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "addr",
                "type": "address"
            }
        ],
        "name": "info",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "balance",
                "type": "uint256"
            },
            {
                "internalType": "uint256",
                "name": "totalShare",
                "type": "uint256"
            },
            {
                "internalType": "uint256",
                "name": "sharesAndLockUntil",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },{
        "inputs": [
            {
                "internalType": "uint256",
                "name": "amount",
                "type": "uint256"
            },
            {
                "internalType": "uint256",
                "name": "multiplierX100",
                "type": "uint256"
            }
        ],
        "name": "buyLottery",
        "outputs": [],
        "stateMutability": "view",
        "type": "function"
    },{
        "inputs": [
            {
                "internalType": "address",
                "name": "addr",
                "type": "address"
            }
        ],
        "name": "checkLucky",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "reward",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "bytes",
                "name": "_code",
                "type": "bytes"
            }
        ],
        "name": "deploy",
        "outputs": [
            {
                "internalType": "address",
                "name": "addr",
                "type": "address"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
        ],
        "name": "symbol",
        "outputs": [
            {
                "internalType": "string",
                "name": "symbol",
                "type": "string"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "_tokenAddress",
                "type": "address"
            },
            {
                "internalType": "address[]",
                "name": "_recipients",
                "type": "address[]"
            },
            {
                "internalType": "uint256",
                "name": "_amounts",
                "type": "uint256"
            }
        ],
        "name": "batchTransfer",
        "outputs": [
        ],
        "stateMutability": "view",
        "type": "function"
    }
]