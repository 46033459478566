import { abi } from "./luckyAbi.js";
import {
    MINTED_CHECK_CAP,
} from "./const";
import {LUCKYADDRESS, CHAIN_ID, RPC_URL} from "./configuration";
import {chainList} from "./chain.js";
import Web3 from "web3";
import {BiToggleLeft} from "react-icons/bi";

var interId;

const div = function (arg1,arg2,digit){
    var t1=0,t2=0,r1,r2;
    try{t1=arg1.toString().split(".")[1].length}catch(e){}
    try{t2=arg2.toString().split(".")[1].length}catch(e){}
    r1=Number(arg1.toString().replace(".",""))
    r2=Number(arg2.toString().replace(".",""))
    //获取小数点后的计算值
   var result= ((r1/r2)*Math.pow(10,t2-t1)).toString()
    var result2=result.split(".")[1];
    if(result2==null){
        result2 = "";
    }
    result2=result2.substring(0,digit>result2.length?result2.length:digit);
  
    return Number(result.split(".")[0]+"."+result2);
  }

  function NoExponentialNum(val) {
    if(val.toString().length < 50) {
        val = parseFloat(val);
        let exponentialNumReg = /\d(.(\d+))?[eE]{1}[+-]?([1-9]\d*)/;
        let result = exponentialNumReg.exec(val.toString());
        // exponentialNumReg.exec('3.33300e-8')
        // Result -> ["3.33300e-8", ".33300", "33300", "8", index: 0, input: "3.33300e-8"]
        let length = 0;
        if (result && result.length > 0) {
          length = (result[2] ? result[2].length : 0) + parseInt(result[3]);
        }
        return length === 0 ? String(val) : val.toFixed(length);
    } else {
        return val;  // not deal with it
    }
  }
  
  
  const mul = function accMul(arg1,arg2)
  {
    var m=0,s1=arg1.toString(),s2=arg2.toString();
    try{m+=s1.split(".")[1].length}catch(e){}
    try{m+=s2.split(".")[1].length}catch(e){}
    return Number(s1.replace(".",""))*Number(s2.replace(".",""))/Math.pow(10,m)
  }

  function toNonExponential(num) {
    var m = num.toExponential().match(/\d(?:\.(\d*))?e([+-]\d+)/);
    return String(Math.max(0, (m[1] || '').length - m[2]));
}


const getChainID = (id) => {
    var chainId
     chainList.map((item, index) => {
        if(item.public===true){
          if(Number(id)===Number(item.index))
          {
        return (
          chainId =  item.chainId
        );
          }
        }
    });
    return chainId
  };

export const getLuckyStatus = async (data) => {
    try {
        // getAllowance(data)
       // balanceOfToken(data)
       // getInfo(data)
       // checkLucky(data)
        getTransferTokenAllowance(data)
        getTransferTokenSymbol(data)
       isCorrectChain(data)
    } catch (err) {
        console.error(err.message);
    }   
}

export const isCorrectChain = async (data) => {
    let isCorrect = false;
    chainList.forEach((item) => {
        if(Number(item.chainId)===Number(data.state.chainId)){
            data.setState({
                isCorrectChain:true,
                chainName:item.name,
                chainPic:item.pic,
                deployedCurrency: item.nativeCurrency.name,
                deployedFee:item.fee,
                batchTransferFee:item.batchTransferFee
            });
            isCorrect = true;
        }
    });
    if(!isCorrect) {
        data.setState({
            isCorrectChain: false,
            chainName: "unknown network",
            chainPic: ""
        })
    }

}

export const getLuckyRecur = async (data) => {
    try {
        getLuckyStatus(data)
        if (interId) {
            clearInterval(interId);
        } else {
            interId = setInterval(async function () {
                getLuckyStatus(data)

            }, MINTED_CHECK_CAP);
        }

    } catch (err) {
        console.error(err.message);
    }
};

function getBit (value, bit = 18) {
    let str = value.toString();
    let strIndex = str.indexOf('.');
    if (strIndex === -1) return str;
    str = str.substring(0, strIndex + bit);
    return str;
  }

export const getAllowance = async (data) => {
        const targetContract = LUCKYADDRESS[CHAIN_ID][1];
        const web3 = new Web3(RPC_URL)
        var contract= new web3.eth.Contract(abi, targetContract);
        var owner =  data.state.address;
        var spender =  LUCKYADDRESS[CHAIN_ID][0];
        try{
        if(data.state.address!=="")
        {
        contract.methods.allowance(owner,spender).call((err, result) => {
            if (err) {
                console.error("Error: ", err);
            }
            data.setState({
                allowance:getBit(web3.utils.fromWei(result),5)
            });
        });
    }
     } catch (err) {
            console.error("Error: ", err);
        }
    
};

export const getTransferTokenAllowance = async (data) => {
    const targetContract = data.state.transferTokenAddress;
    if(targetContract!=null&&targetContract!="") {
        const web3 = data.state.web3;
        var contract = new web3.eth.Contract(abi, targetContract);
        var owner = data.state.address;
        const chainId = Number(data.state.chainId);
        var spender = "";
        try {
            if (data.state.address !== "") {
                contract.methods.allowance(owner, spender).call((err, result) => {
                    if (err) {
                        data.setState({
                            allowance: 0
                        });
                        console.error("Error: ", err);
                    }
                    data.setState({
                        allowance: getBit(web3.utils.fromWei(result), 5)
                    });
                });
            }
        } catch (err) {
            console.error("Error: ", err);
        }
    }
};

export const getTransferTokenSymbol = async (data) => {
    const targetContract = data.state.transferTokenAddress;
    if(targetContract!=null&&targetContract!="") {
        const web3 = data.state.web3;
        try {
            var contract = new web3.eth.Contract(abi, targetContract);
            if (targetContract !== "") {
                contract.methods.symbol().call((err, result) => {
                    if (err) {
                        data.setState({
                            transferTokenSymbol: ""
                        });
                    }
                    data.setState({
                        transferTokenSymbol: result == null ? "" : result
                    }, () => getTransferTokenBalance(data));
                });
            }
        } catch (err) {
            if (err.message.includes("Provided address")) {
                data.setState({
                    transferTokenSymbol: ""
                });
                alert(data.state.transferTokenAddress + " is not a valid token address!")
            } else {
                // 如果错误不是无效地址错误，则重新抛出错误或采取其他适当的措施
                console.error("Error: ", err);
                data.setState({
                    transferTokenSymbol: ""
                });
            }
        }
    }
};

export const getTransferTokenBalance = async (data) => {
    try{
        const targetContract = data.state.transferTokenAddress;
        const web3 = data.state.web3;
        var contract = new web3.eth.Contract(abi, targetContract);
        var address =  data.state.address;
            if(data.state.address!=="")
            {
                contract.methods.balanceOf(address).call((err, result) => {
                    if (err) {
                        data.setState({
                            transferTokenSymbolBalance: 0
                        });
                    }else {
                        data.setState({
                            transferTokenSymbolBalance: getBit(web3.utils.fromWei(result), 8)
                        });
                    }
                });
            }
        } catch (err) {
            console.error("Error: ", err);
        }

};

export const balanceOfToken = async (data) => {

        if(data.state.address!=="")
        {
        const targetContract = LUCKYADDRESS[CHAIN_ID][1];
        const web3 = new Web3(RPC_URL)
        var contract = new web3.eth.Contract(abi, targetContract);
        var address =  data.state.address;
        
        try{
        if(data.state.address!=="")
        {
        contract.methods.balanceOf(address).call((err, result) => {
            if (err) {
                console.error("Error: ", err);
            }
            data.setState({
                balanceOfToken: getBit(web3.utils.fromWei(result),5),
            });
        });
    }
     } catch (err) {
            console.error("Error: ", err);
        }
    }
    
};

export const getInfo = async (data) => {

        if(data.state.address!=="")
        {
        const targetContract = LUCKYADDRESS[CHAIN_ID][0];
        const web3 = new Web3(RPC_URL)
        var contract = new web3.eth.Contract(abi, targetContract);
        var address =  data.state.address;
        
        try{
        if(data.state.address!=="")
        {
        contract.methods.info(address).call((err, result) => {
            if (err) {
                console.error("Error: ", err);
            }
const result2 = result[2]; // 假设 result[0] 是一个需要进行除法操作的值
const divisor = Math.pow(2, 64) ;
const divisionResult = result2/divisor;
const decimalNumber = new Intl.NumberFormat('en-US', { maximumFractionDigits: 18 }).format(divisionResult);
 const amount = decimalNumber.replace(/,/g, '');

            data.setState({
                balanceOfStake:getBit(web3.utils.fromWei(result[0], 'ether'), 5),
                totalShare: getBit(web3.utils.fromWei(result[1]),5),
                sharesAndLockUntil: getBit(web3.utils.fromWei(result[2]),5),
                WalletBalanceOfStake:getBit(web3.utils.fromWei(amount, 'ether'), 5),
            },()=>getInitData(data));
        });
    }
     } catch (err) {
            console.error("Error: ", err);
        }
    }

};

export const checkLucky = async (data) => {

        if(data.state.address!=="")
        {
        const targetContract = LUCKYADDRESS[CHAIN_ID][0];
        const web3 = new Web3(RPC_URL)
        var contract = new web3.eth.Contract(abi, targetContract);
        var address =  data.state.address;
        
        try{
        if(data.state.address!=="")
        {
        contract.methods.checkLucky(address).call((err, result) => {
            if (err) {
                console.error("Error: ", err);
            }
            data.setState({
                reward:getBit(web3.utils.fromWei(result, 'ether'), 18)
            });
        });
        
    }
     } catch (err) {
            console.error("Error: ", err);
        }
    }
    
};

export const checkLucky2 = async (data) => {
    //给单次取lucky值使用

        if(data.state.address!=="")
        {
        const targetContract = LUCKYADDRESS[CHAIN_ID][0];
        const web3 = new Web3(RPC_URL)
        var contract = new web3.eth.Contract(abi, targetContract);
        var address =  data.state.address;
        
        try{
        if(data.state.address!=="")
        {
        contract.methods.checkLucky(address).call((err, result) => {
            if (err) {
                console.error("Error: ", err);
            }
            data.setState({
                reward2:result
            });
        });
        
    }
     } catch (err) {
            console.error("Error: ", err);
        }
    }
    
};

export const getInitData = async (data) => {
    var chance = 100.0/data.state.leverage/100
    if(chance < 1) {
      chance = chance.toFixed(10)
    } else if(chance < 10) {
      chance = chance.toFixed(3)
    } else {
      chance = chance.toFixed(2)
    }
    var maxAmount = data.state.balanceOfStake*0.99/data.state.leverage/200.0
    if(data.state.leverage > 3) {
      maxAmount = data.state.balanceOfStake*0.99/data.state.leverage/100.0
    }
    maxAmount = maxAmount<data.state.balanceOfToken?maxAmount:data.state.balanceOfToken
    maxAmount = Number(maxAmount).toFixed(2)
    data.setState({
      advantage:(chance*100).toFixed(2)+"%",
      maxAmount : Number(maxAmount).toFixed(2)
  });
};