import React from 'react';

import { useState } from "react";

import { setWin,setLose,setDraw} from "../../utils/bet";
import { MAX_BATCH_SIZE,NFT_NAME,} from "../../utils/configuration";
import Paper from '@mui/material/Paper';
import { experimentalStyled as styled } from '@mui/material/styles';
import { FOOT } from "../index/foot.js";


var isGetBalance = false
const team1 = "QATAR";
const team2 = "ECUADOR";
const ctx = require.context("../../assets/slider-imgs", true, /.png$/);
let images = [];
ctx.keys().forEach((key) => {
  images.push(ctx(key));
});

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(2),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

export const ABOUT = ({ data }) => {




const NumberDiv = function (arg1,arg2,digit){
  var t1=0,t2=0,r1,r2;
  try{t1=arg1.toString().split(".")[1].length}catch(e){}
  try{t2=arg2.toString().split(".")[1].length}catch(e){}
  r1=Number(arg1.toString().replace(".",""))
  r2=Number(arg2.toString().replace(".",""))
  //获取小数点后的计算值
 var result= ((r1/r2)*Math.pow(10,t2-t1)).toString()
  var result2=result.split(".")[1];
  if(result2==null){
      result2 = "";
  }
  result2=result2.substring(0,digit>result2.length?result2.length:digit);

  return Number(result.split(".")[0]+"."+result2);
}

const multiply = function accMul(arg1,arg2)
{
  var m=0,s1=arg1.toString(),s2=arg2.toString();
  try{m+=s1.split(".")[1].length}catch(e){}
  try{m+=s2.split(".")[1].length}catch(e){}
  return Number(s1.replace(".",""))*Number(s2.replace(".",""))/Math.pow(10,m)
}

  function setMax(){
    data.setState({
      betAmount: data.state.balance
  });
   }

   function add(){
    var amount = ++data.state.mintAmout;
    data.setState({
      mintAmout: data.state.balance==null?1:amount>=MAX_BATCH_SIZE-data.state.balance?MAX_BATCH_SIZE-data.state.balance:amount,
  });
}

   function remove(){
    var amount = --data.state.mintAmout;
    data.setState({
      mintAmout: data.state.balance==null?1:amount<=0?1:amount,
  });
   }


   const getSellOupt = (e) => {
    var len1 = e.target.value.substr(0, 1);
    var text = /^[0-9]+$/

    if(e.target.value==="")
    {
    data.setState({
      mintAmout:e.target.value,
     });
     }else if (len1 === "0") {
      data.setState({
        mintAmout:"1",
       });
     } else if (e.target.value>MAX_BATCH_SIZE-data.state.balance) {
      data.setState({
        mintAmout:MAX_BATCH_SIZE-data.state.balance,
       });
     }else if(text.test(e.target.value))
    {
    data.setState({
      mintAmout:e.target.value,
  });
    }
   }

   const getDepositOuput = (e) => {
    var len1 = e.target.value.substr(0, 1);
    var len2 = e.target.value.substr(1, 1);
    var text = /(^[0-9]+.{0,1}[0-9]{0,2}$)/
  
    if(e.target.value==="")
    {
    data.setState({
      betAmount:e.target.value,
     });
     }
     else if (len1 === "0"&&len2 !== "."&&len2 !== "") {
      data.setState({
        betAmount:len2,
       });
     } 
     else if (Number(e.target.value)>Number(data.state.balance)) {
      data.setState({
        betAmount:data.state.balance,
       });
     }else if(text.test(e.target.value))
    {
    data.setState({
      betAmount:e.target.value,
  });
    }
   }

  const [top, setTop] = useState(0);
  const [open, setOpen] = React.useState(false);
  const [betMethod, setBetMethod] = React.useState(0);  

  const handleClickOpen = (e) => {
    data.setState({
      betAmount:"",
  });
     setBetMethod(e)
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOK = () => {
    if(betMethod==="WIN"){
      setWin(data)
    }
    if(betMethod==="DRAW"){
      setDraw(data)
    }
    if(betMethod==="LOSE"){
      setLose(data)
    }
  };




  return (
    <div>
        <div>
      <nav className={"navbar  navbar-inverse"}></nav>
      <div className={"main-section"}>
      <div className={"main-section-title"}>{NFT_NAME}</div>
        <div className={"main-section-title-mobile"}>{NFT_NAME}</div>

        </div>
        <div>
        <FOOT  data={data}  />
      </div>
      </div>
    </div>
  );
};


