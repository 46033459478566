import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import IndexPage from './pages/index';
import React from "react";
import './App.css'

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      provider: {},
      web3: {},
      account: {},
      mintAmout: 1,
      mintType: "",
      mintTransaction: "",
      pendingRequest: false,
      connected: false,
      txn: "",
      finish: false,
      mintedNum: 0,
      mintableNum: -1,
      tier: 0,
      address: "",
      balance:0,
      containedModalShow: "",
      modalDialogTitle: "",
      modalDialogBodyText: "",
      modalDialogBodyHref: "",
      mintErrorMsg: "",
      isWhiteList: false,
      proof:[],
      percentage:"",
      airDropAmount:0,
      isClaimable:false,
      isGetBalance:false,
      totalCollectable:0,
      saleAmount:0,
      menuName:"",
      collapsed: false,
      width: 0, 
      left: 0,
      totalBet:0,
      poolWinBet:0,
      poolDrawBet:0,
      poolLoseBet:0,
      walletWinBet:0,
      walletDrawBet:0,
      walletLoseBet:0,
      betAmount:"",
      rewardAmount:0,
      rewardIsActive:false,
      quizIsActive:false,
      numPages: null,
      pageNumber: 1,
      bridgeBalance:0,
      depositAmount:"",
      contractAddress:"",
      coinAddress:"",
      fromChain:10,
      toChain:20,
      fromCoin:10,
      toCoin:20,
      chainIdTo:0,
      stakeRankList:[],
      inviterRankList:[],
      balance_total_reward:"",
      leverage:10,
      maxAmount:0,
      fee:0,
      localLang:'en-US',
      tokenTemplate:0,
      tokenAddress:"",
      tokenName:"",
      tokenSymbol:"",
      tokenDecimal:"18",
      tokenTotalSupply:"",
      deployedTokenName:"",
      deployedTokenAddress:"",
      deployedCurrency:"",
      deployFee:0,
      batchTransferFee:0,
      percentage:0,
      mintAmount:0,
      isRenounce:false,
      transferTokenAddress:"",
      transferTokenSymbol:"",
      transferTokenSymbolBalance:"",
      transferTokenAmount:"",
      transferTokenDest:"",
      transferTokenLength:1

    };
    this.web3Modal = {};
  }

  componentDidMount() {
     // document.body.style.background = '#000000'
    // document.body.style.minWidth='100%'
    // document.body.style.width='100%'
  }


  render() {
    return (
      <IndexPage  data={this} />
    );
  }
}

export default App;
