import React,{ Component } from 'react';
import {HashRouter, Router,Routes,Route, Link,Navigate } from 'react-router-dom';
import { onConnect,switchChain,setChainInfo} from "../../utils/wallet";
import { Affix} from 'antd';
import { formatWalletAddress } from "../../utils/util";
import "./index.css";
import Web3Modal from "web3modal";
import { useEffect, useState } from "react";
import WalletLink from "walletlink";
import WalletConnectProvider from "@walletconnect/web3-provider";
import head from "../../assets/ai.jpg";
import hamburger from "../../assets/hamburger.webp";
import { MAX_BATCH_SIZE,NFT_NAME,DEFAULT_CHAIN_ID} from "../../utils/configuration";
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import MenuItem from '@mui/material/MenuItem';
import { experimentalStyled as styled } from '@mui/material/styles';
import { HOME } from "./home.js";
import { Menu } from 'antd';
import { ABOUT } from "../about/about.js";
import MenuMui from '@mui/material/Menu';
import MenuItemMui from '@mui/material/MenuItem';
import { getMintedRecur} from "../../utils/status.js";
import intl from 'react-intl-universal';
import LanguageIcon from '@mui/icons-material/Language';
import Divider from '@mui/material/Divider';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import {  alpha } from '@mui/material/styles';
 import CustomMenu from '@mui/material/Menu';
import {chainList} from "../../utils/chain.js";
import {locales} from "../../utils/locales";



// const locales = {
//     "en-US": require('../../locales/en-US.json'),
//     "zh-CN": require('../../locales/zh-CN.json'),
// };

const { SubMenu } = Menu;


var isGetBalance = false




const ctx = require.context("../../assets/slider-imgs", true, /.png$/);
let images = [];
ctx.keys().forEach((key) => {
  images.push(ctx(key));
});



const languageList = [
    {
        title: "中文", // 菜单标题名称
        key: "0", // 对应的path
        public: true, // 公开的
    },{
        title: "English", // 菜单标题名称
        key: "1", // 对应的path
        public: true, // 公开的
    }
];


const StyledMenu = styled((props) => (
    <CustomMenu
        elevation={0}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
        }}
        {...props}
    />
))(({ theme }) => ({
    '& .MuiPaper-root': {
        borderRadius: 6,
        marginTop: theme.spacing(1),
        minWidth: 180,
        color:
            theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
        boxShadow:
            'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
        '& .MuiMenu-list': {
            padding: '4px 0',
        },
        '& .MuiMenuItem-root': {
            '& .MuiSvgIcon-root': {
                fontSize: 18,
                color: theme.palette.text.secondary,
                marginRight: theme.spacing(1.5),
            },
            '&:active': {
                backgroundColor: alpha(
                    theme.palette.primary.main,
                    theme.palette.action.selectedOpacity,
                ),
            },
        },
    },
}));


const providerOptions = {
  walletlink: {
    package: WalletLink, // Required
    options: {
      appName: {NFT_NAME}, // Required
      infuraId: "INFURA_ID", // Required unless you provide a JSON RPC url; see `rpc` below
      rpc: "", // Optional if `infuraId` is provided; otherwise it's required
      chainId: 1, // Optional. It defaults to 1 if not provided
      appLogoUrl: null, // Optional. Application logo image URL. favicon is used if unspecified
      darkMode: false, // Optional. Use dark theme, defaults to false
    },
  },
  walletconnect: {
    display: {
      name: "Mobile",
    },
    package: WalletConnectProvider,
    options: {
      infuraId: "INFURA_ID", // required
    },
  },
};

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(2),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));


const IndexPage = ({ data }) => {

    const menuList = [
        {
            title: intl.get('HOME'), // 菜单标题名称
            key: "/", // 对应的path
            public: true, // 公开的
        },{
            title: intl.get('Maketplace'), // 菜单标题名称
            key: "/nft", // 对应的path
            public: true, // 公开的
        },{
            title: intl.get('Deploy'), // 菜单标题名称
            key: "/nft", // 对应的path
            public: true, // 公开的
        },{
            title: intl.get('TRANSFER'),
            key: "/nft", // 对应的path
            public: true, // 公开的
        },{
            title: intl.get('AISTUDIO'),
            key: "/nft", // 对应的path
            public: true, // 公开的
        }
    ];

    const getMenuNodeList = (menuList) => {
        return menuList.map((item, index) => {
            if (item.items) {
                return (
                    <SubMenu
                        key={item.key}
                        title={
                            <span>
              <span>{item.title}</span>
            </span>
                        }
                    >
                        {/* {getMenuNodeList(item.items)} */}
                    </SubMenu>
                );
            } else {
                if(item.public===true){
                    return (
                        <Menu.Item  key={item.key}>
                            <Link to={item.key}>
                                <span className={"header-section-title"} style={{color:"#eab918"}}>{item.title}</span>
                            </Link>
                        </Menu.Item>

                    );
                }else{
                    return (
                        <Menu.Item key={item.key}>
                            <a href={item.key} target="_blank" rel="noreferrer" className={"footer-section-title-media"} style={{color:"#eab918"}}>{item.title}</a>
                        </Menu.Item>

                    );
                }
            }
        });
    };

    const getMenuMuiNodeList = (menuList) => {
        return menuList.map((item, index) => {
            if (item.items) {
                return (
                    <SubMenu
                        key={item.key}
                        title={
                            <span>
              <span>{item.title}</span>
            </span>
                        }
                    >
                        {getMenuNodeList(item.items)}
                    </SubMenu>
                );
            } else {
                if(item.public===true){
                    return (
                        <MenuItemMui  key={item.key}>
                            <Link to={item.key}>
                                <span className={"liz_menu_item_mobile"} style={{color:"#5862f5"}}>{item.title}</span>
                            </Link>
                        </MenuItemMui>

                    );
                }else{
                    return (
                        <MenuItemMui key={item.key}>
                            <a href={item.key} target="_blank" rel="noreferrer" className={"liz_menu_item_mobile"} style={{color:"#eab918"}}>{item.title}</a>
                        </MenuItemMui>

                    );
                }
            }
        });
    };

    const getLanguageNodeList = (languageList) => {
        return languageList.map((item, index) => {

            if (item.items) {
                return (
                    <SubMenu
                        key={item.key}
                        title={
                            <span>
              <span>{item.title}</span>
            </span>
                        }
                    >
                        {getMenuNodeList(item.items)}
                    </SubMenu>
                );
            } else {
                if(item.public===true){
                    return (
                        <MenuItemMui  onClick={(e)=>setLanguageItem(item.key,e)}  key={item.key}>
                            <span className={"liz_menu_item_mobile"}  style={{color:"#5862f5"}} >{item.title}</span>
                        </MenuItemMui>

                    );
                }else{
                    return (
                        <MenuItemMui key={item.key}>
                            <a href={item.key} target="_blank" rel="noreferrer" className={"liz_menu_item_mobile"} style={{color:"#eab918"}}>{item.title}</a>
                        </MenuItemMui>

                    );
                }
            }
        });
    };

    const getMainNetList = (chainList) => {
        return chainList.map((item, index) => {
                if(item.public===true&&item.type===1){
                    return (
                        (data.state.chainId === item.chainId)?(
                            <MenuItem key={index} style={{ fontWeight: 'bold' , color: 'red'}}
                                      onClick={(e) => setChainInfo(item, data, e)} >
                                <img src={item.pic} style={{width: "30px"}} alt=""/>
                                &nbsp;&nbsp;&nbsp;&nbsp;{item.name}
                            </MenuItem>):(
                            <MenuItem key={index} onClick={(e) => setChainInfo(item, data, e)} >
                                <img src={item.pic} style={{width: "30px"}} alt=""/>
                                &nbsp;&nbsp;&nbsp;&nbsp;{item.name}
                            </MenuItem>
                        )

                    );
                }
        });
    };
    const getTestNetList = (chainList) => {
        return chainList.map((item, index) => {
            if(item.public===true&&item.type===2){
                return (
                    (data.state.chainId === item.chainId)?(
                        <MenuItem key={index} style={{ fontWeight: 'bold' , color: 'red'}}
                                  onClick={(e) => setChainInfo(item, data, e)} >
                            <img src={item.pic} style={{width: "30px"}} alt=""/>
                            &nbsp;&nbsp;&nbsp;&nbsp;{item.name}
                        </MenuItem>):(
                        <MenuItem key={index} onClick={(e) => setChainInfo(item, data, e)} >
                            <img src={item.pic} style={{width: "30px"}} alt=""/>
                            &nbsp;&nbsp;&nbsp;&nbsp;{item.name}
                        </MenuItem>
                    )

                );
            }
        });
    };

    const setLanguageItem = (key,e) => {

        switch (Number(key)) {
            case 0:
                data.setState({
                    localLang: "zh-CN"
                });
                break;
            case 1:
                data.setState({
                    localLang:"en-US"
                });
                break;
            default:
                data.setState({
                    localLang:"en-US"
                });
                break;
        }
    }

    intl.init({currentLocale: data.state.localLang, locales})

    data.web3Modal = new Web3Modal({
    network: "mainnet",
    cacheProvider: true,
    providerOptions: providerOptions,
  });

  const [count, setCount] = useState(1);
  
const NumberDiv = function (arg1,arg2,digit){
  var t1=0,t2=0,r1,r2;
  try{t1=arg1.toString().split(".")[1].length}catch(e){}
  try{t2=arg2.toString().split(".")[1].length}catch(e){}
  r1=Number(arg1.toString().replace(".",""))
  r2=Number(arg2.toString().replace(".",""))
  //获取小数点后的计算值
 var result= ((r1/r2)*Math.pow(10,t2-t1)).toString()
  var result2=result.split(".")[1];
  if(result2==null){
      result2 = "";
  }
  result2=result2.substring(0,digit>result2.length?result2.length:digit);

  return Number(result.split(".")[0]+"."+result2);
}

const multiply = function accMul(arg1,arg2)
{
  var m=0,s1=arg1.toString(),s2=arg2.toString();
  try{m+=s1.split(".")[1].length}catch(e){}
  try{m+=s2.split(".")[1].length}catch(e){}
  return Number(s1.replace(".",""))*Number(s2.replace(".",""))/Math.pow(10,m)
}

  function setMax(){
    data.setState({
      mintAmout: data.state.balance==null?1:MAX_BATCH_SIZE-data.state.balance
  });
   }

   function add(){
    var amount = ++data.state.mintAmout;
    data.setState({
      mintAmout: data.state.balance==null?1:amount>=MAX_BATCH_SIZE-data.state.balance?MAX_BATCH_SIZE-data.state.balance:amount,
  });
}

   function remove(){
    var amount = --data.state.mintAmout;
    data.setState({
      mintAmout: data.state.balance==null?1:amount<=0?1:amount,
  });
   }
   const setMenu = () =>{
    data.setState({
       menuName:'/'+[window.location.hash.split('/')[1]]
    })
      }

   const getSellOupt = (e) => {
    var len1 = e.target.value.substr(0, 1);
    var text = /^[0-9]+$/

    if(e.target.value==="")
    {
    data.setState({
      mintAmout:e.target.value,
     });
     }else if (len1 === "0") {
      data.setState({
        mintAmout:"1",
       });
     } else if (e.target.value>MAX_BATCH_SIZE-data.state.balance) {
      data.setState({
        mintAmout:MAX_BATCH_SIZE-data.state.balance,
       });
     }else if(text.test(e.target.value))
    {
    data.setState({
      mintAmout:e.target.value,
  });
    }
   }

    useEffect(() => {
        (async () => {
            if (localStorage.getItem("WEB3_CONNECT_CACHED_PROVIDER"))
            {
                try {
            await getMintedRecur(data)
            await onConnect(data);
                } catch (err) {
                    console.error('Wallet connection failed. Reason:', err.message);
                }
            }else{
                await getMintedRecur(data)
            }
        })();
    }, [data]);
  const [top, setTop] = useState(0);


    const [anchorEl, setAnchorEl] = React.useState(null);
    const [chainEl, setChainEl] = React.useState(null);
    const [languageEl, setLanguageEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const openChain = Boolean(chainEl);
    const openLanguage = Boolean(languageEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
    const handleChainClick = (event) => {
        setChainEl(event.currentTarget);
    };
    const handleLanguageClick = (event) => {
        setLanguageEl(event.currentTarget);
    };
  const handleClose = () => {
    setAnchorEl(null);
  };
    const handleChainClose = () => {
        setChainEl(null);
    };


    const handleLanguageClose = () => {
        setLanguageEl(false);
    };

  return (
    <HashRouter>
    <div className="home-container">
    <Affix className="affix" offsetTop={top}>
    <div className="liz_header" id="navbar">
          <div className="affix_menu">
           <Menu
          className="liz_menu_sect"
          defaultSelectedKeys={["/"]}
          mode="horizontal"
          theme="dark"
        >
          {getMenuNodeList(menuList)}
        </Menu>
        </div>
        <div className="liz_menu_mobile">
        <Button
        className="liz_menu_button"
        style={{color:"white"}}
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      >
    <img src={hamburger} style={{width:"30px"}} alt=""/>
  </Button>
      <MenuMui
        id="basic-menu"
        anchorEl={anchorEl}
        disableScrollLock={ true }
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
         {getMenuMuiNodeList(menuList)}
      </MenuMui>
      </div>
        <div >
{(data.state.connected===true&&data.state.address!=="") ? (
    <div>
  <button className="head-button"  data={data}
  disabled={true}
 >{formatWalletAddress(data.state.address)}</button>
        <button className="head-button-mobile"  data={data}
                disabled={true}
        > {formatWalletAddress(data.state.address)}</button>
    </div>
        ): (
          <div>
          <button className="head-button"  data={data}
          onClick={() => {
              onConnect(data).then(result=> console.log(data)).catch  (
                  error => console.error('Wallet connection failed. Reason:', data)
              )
          }}
        >{intl.get('CONNECT_WALLET')}
        </button>
              <button className="head-button-mobile"  data={data}
                      onClick={() => {
                              onConnect(data).catch  (
                                  error => console.error('Wallet connection failed. Reason:', error.message)
                      )
                      }}
              >{intl.get('CONNECT_WALLET')}
              </button>
        </div>
        )}

</div>
<div className="logo">
<a href="/">
<img src={head} className="head-section-img" alt=""/>
</a>
</div>
<div >

</div>
</div>
</Affix >
<Routes>   
    <Route path="/" element={<HOME  data={data}/>}></Route>
    <Route path="/about"element={<ABOUT  data={data}/>}></Route>
    <Route path="/nft"element={<ABOUT  data={data}/>}></Route>
</Routes> 
</div>
</HashRouter>        

  );
};

export default IndexPage;
