

export const chainList = [
    {
        name: "BNB SMART CHAIN", // 菜单标题名称
        index: 10,
        pic: "https://ggg.dog/images/chains/56.png",
        chainId: 56,
        chainName: "BNB",
        rpcUrls: ["https://bsc-dataseed3.binance.org","https://bsc-dataseed2.defibit.io","https://bsc-dataseed.binance.org"],
        type:1, //1: 主网，2:测试网
        fee: 0,
        batchTransferFee:0.02,
        nativeCurrency: {
            name: "BNB",
            symbol: "BNB",
            decimals: 18,
        },
        blockExplorerUrls: ["https://bscscan.com//"],
        public: true, // 公开的
    },
    {
        name: "BNB TEST CHAIN", // 菜单标题名称
        index:20,
        pic: "https://ggg.dog/images/chains/97.png",
        chainId: 97,
        chainName: "BNB Test chain",
        rpcUrls: ["https://endpoints.omniatech.io/v1/bsc/testnet/public","https://bsc-testnet.publicnode.com"],
        type:2, //1: 主网，2:测试网
        fee: 0,
        batchTransferFee:0,
        nativeCurrency: {
            name: "TBNB",
            symbol: "TBNB",
            decimals: 18,
        },
        blockExplorerUrls: ["https://testnet.bscscan.com/"],
        public: true, // 公开的
    },
    {
        name: "SHIBARIUM", // 菜单标题名称
        index: 30,
        pic: "https://token-monitor.s3.amazonaws.com/1693064039168cropped-1693064039120.jpeg",
        chainId: 109,
        chainName: "Shibarium",
        rpcUrls: ["https://www.shibrpc.com"],
        type:1, //1: 主网，2:测试网
        fee: 0,
        batchTransferFee:4,
        nativeCurrency: {
            name: "BONES",
            symbol: "BONES",
            decimals: 18,
        },
        blockExplorerUrls: ["https://www.shibariumscan.io/"],
        public: true, // 公开的
    },
    {
        name: "BITCHAIN", // 菜单标题名称
        index: 40,
        pic: "https://bridge.bitchain.biz/assets/logo-a411faf1.png",
        chainId: 198,
        chainName: "BITCHAIN",
        rpcUrls: ["https://rpc.bitchain.biz"],
        type:1, //1: 主网，2:测试网
        fee: 0,
        batchTransferFee:0,
        nativeCurrency: {
            name: "BTC",
            symbol: "BTC",
            decimals: 18,
        },
        blockExplorerUrls: ["https://explorer.bitchain.biz/"],
        public: true, // 公开的
    },
    {
        name: "Goerli", // 菜单标题名称
        index: 50,
        pic: "https://assets.coingecko.com/coins/images/29217/small/goerli-eth.png?1677429831",
        chainId: 5,
        chainName: "Goerli",
        rpcUrls: ["https://goerli.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161","https://eth-goerli.g.alchemy.com/v2/demo","https://ethereum-goerli.publicnode.com"],
        type:2, //1: 主网，2:测试网
        fee: 0,
        batchTransferFee:0,
        nativeCurrency: {
            name: "ETH",
            symbol: "ETH",
            decimals: 18,
        },
        blockExplorerUrls: ["https://goerli.etherscan.io/"],
        public: true, // 公开的
    }
];
